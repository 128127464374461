<template>
  <div class="container">
    <newAddOrder type="vue-supply-order-web"/>
  </div>
</template>

<script>
export default {
  name: 'AddOrder',
  components: {},
  data() {
    return {}
  },
  mounted() {},
  methods: {},
}
</script>
<style lang="less" scoped>
.container {
  height: 100%;
}
</style>
